import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const CustomRegularCarousel = ({ slides, autoplay, layout, slidesToShow }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(!autoplay);
  const textContainerRef = useRef(null);

  setInterval(() => {
    if (!isPaused) {
      // Using prevIndex to ensure we get the latest state value
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }
  }, 3000);

  const nextSlide = () => {
    // Using prevIndex to ensure we get the latest state value
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const prevSlide = () => {
    // Using prevIndex to ensure we get the latest state value
    setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  const handleReadMore = () => {
    if (textContainerRef.current) {
      textContainerRef.current.classList.toggle('max-h-20');
      textContainerRef.current.classList.toggle('max-h-full');
    }
  };

  return (
    <div className={`relative w-full h-full ${layout}`}>
      <div className="flex items-center justify-between w-full h-full">
        <button onClick={prevSlide} className="p-2 bg-white rounded-full shadow-lg">
          &lt;
        </button>
        <div className="carousel-container flex items-center overflow-hidden">
          <div
            className="carousel-inner flex transition-transform duration-500 ease-in-out"
            style={{
              transform: `translateX(-${(currentIndex / slidesToShow) * 100}%)`,
              width: `${slides.length * (100 / slidesToShow)}%`,
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                className="carousel-item flex-shrink-0 w-full lg:w-1/2 px-2"
                style={{ width: `${100 / slidesToShow}%` }}
              >
                <div className="flex items-center justify-center w-full h-full space-y-4">
                  <div className="w-full h-1/2 lg:h-full">
                    {slide.video ? (
                      <iframe
                        width="100%"
                        height="100%"
                        src={slide.video}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                      ></iframe>
                    ) : (
                      <img
                        className="object-cover rounded-xl"
                        src={slide.image}
                        alt="Slide"
                        height="400"
                        width="500"
                      />
                    )}
                  </div>
                  <div className="flex flex-col items-start w-full px-4 space-y-2 lg:w-1/2">
                    <div
                      ref={textContainerRef}
                      className="overflow-hidden text-left max-h-20 transition-max-height duration-300 ease-in-out"
                    >
                      <p>{slide.text}</p>
                    </div>
                    <button onClick={handleReadMore} className="text-orange-500">
                      Read more
                    </button>
                    <div className="flex items-center space-x-4">
                      <img
                        className="object-cover w-12 h-12 rounded-full"
                        src={slide.authorImage}
                        alt={slide.author}
                      />
                      <div>
                        <h4>{slide.author}</h4>
                        <p>{slide.designation}</p>
                      </div>
                      <img className="h-10" src={slide.logo} alt="Logo" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <button onClick={nextSlide} className="p-2 bg-white rounded-full shadow-lg">
          &gt;
        </button>
      </div>
    </div>
  );
};

CustomRegularCarousel.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      video: PropTypes.string,
      text: PropTypes.string,
      author: PropTypes.string,
      designation: PropTypes.string,
      logo: PropTypes.string,
      authorImage: PropTypes.string,
    })
  ).isRequired,
  autoplay: PropTypes.bool,
  layout: PropTypes.string,
  slidesToShow: PropTypes.number,
};

CustomRegularCarousel.defaultProps = {
  autoplay: false,
  layout: '',
  slidesToShow: 1,
};

export default CustomRegularCarousel;
