import React from "react"
import WebcastCarousel from "../components/WebcastCarousel/WebcastCarousel"
import WebcastCarouselIndia from "../components/WebcastCarousel/WebcastCarouselIndia"
import CHPCalc from "../tools/CHP-calculator"
import CustomRegularCarousel from "../components/Carousels/CustomRegularCarousel"

const slides = [
  {
    image: 'https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/05/product-explainer-thumbnail-shrm.jpg',
    text: 'We are very happy to be associated with InfyGold+, a part of Rewards and Recognition Program at Infosys, and we run this program with Vantage Circle. It has been awesome to have worked with Vantage Circle and the team has helped us provide great experiences to our employees through a single platform...',
    author: 'Shruthi Bhopiah',
    designation: 'AVP and Head of Internal Communication',
    logo: 'https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/Infosys.webp',
    authorImage: 'https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/09/SB-1-1.webp',
  },
  {
    image: 'https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/05/product-explainer-thumbnail-shrm.jpg',
    text: 'We are very happy to be associated with InfyGold+, a part of Rewards and Recognition Program at Infosys, and we run this program with Vantage Circle. It has been awesome to have worked with Vantage Circle and the team has helped us provide great experiences to our employees through a single platform...',
    author: 'Shruthi Bhopiah',
    designation: 'AVP and Head of Internal Communication',
    logo: 'https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/05/Infosys.webp',
    authorImage: 'https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2023/09/SB-1-1.webp',
  },
  // Add more slides here
];

const secondTest = () => {
  return (
    <>
      <div id="CHPCalculator"></div>

      <div id="webcastcarouselIndia"></div>

      <section class="w-full flex flex-col place-items-center bg-white">
        <div class="newSectionContainer flex flex-col place-content-center">
          <h2 class="section-title mx-auto text-center">
            <span class="relative">
              Winner{" "}
              <img
                class="absolute bottom-0 right-0"
                src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/03/HP-Hero-Stroke.png"
              />
            </span>
            of Top Global Awards in Employee Recogntion and Engagement
          </h2>
          <div class="grid grid-cols-1 lg:grid-cols-2 justify-center my-3 lg:my-16 max-w-7xl px-2">
            <div class="flex flex-col justify-center items-center pt-8 lg:pt-16 pb-6 px-2 lg:px-10 lg:border-r">
              <div className="flex justify-center py-10">
                <img
                  loading="lazy"
                  src="https://res.cloudinary.com/vantagecircle/image/upload/v1657697155/gatsbycms/uploads/2022/09/Bakers-Dozen.png"
                  width="350"
                  height="150"
                />
              </div>
              <p class="text-center text-xl ptag pt-8">
                Vantage Circle named as one of the top 10 recognition program
                providers in the{" "}
                <span class="text-underline">
                  HRO Today's Baker Dozen List for 2022.
                </span>{" "}
                The Customer Satisfaction Ratings are based solely on feedback
                from buyers of the rated services collected annually.
              </p>
            </div>
            <div class="flex flex-col justify-center items-center pt-10 lg:pt-16 px-2 lg:pb-10 lg:px-10">
              <div className="flex justify-center py-12">
                <img
                  loading="lazy"
                  src="https://res.cloudinary.com/vantagecircle/image/upload/v1657697155/gatsbycms/uploads/2022/09/Brandon-Hall.png"
                  width="350"
                  height="150"
                />
              </div>
              <p class="text-center text-xl pt-12 ptag">
                Vantage Circle has been awarded the Brandon Hall Excellence
                Awards (Silver) in Best Advance in Employee Engagement category.
                The Brandon Hall Group analysts and international industry
                experts evaluate the winner with a rigorous judging process.
              </p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-6"></div>
        <div className="top-20"></div>
        <div className="top-28"></div>
        <div className="top-16"></div>
        <div className="top-24"></div>
        <div className="top-40"></div>
      </section>
      
      <div>
        <h1 className="text-center text-4xl font-bold mb-8">Real people, Real experiences.</h1>
        <CustomRegularCarousel slides={slides} autoplay={true} layout="flex flex-col lg:flex-row" slidesToShow={2} />
      </div>
    </>
  )
}

export default secondTest
